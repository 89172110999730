import React from 'react'
import { Layout } from '../../components/layout'
import { CategoriesAndTags } from '../../components/categories-and-tags'
import { SEO } from '../../components/seo'

const TagsPage = () => {
	return (
		<Layout>
			<SEO
				title={`TAGS | RUBIA ルビア 渋谷メキシカン【朝食・ランチ・バー】`}
				description=""
				keywords={['メキシコ料理', '渋谷' , 'ファインメキシカン', 'ルビア', 'sarasa']}
			/>
			<h1 className="h1">TAGS | RUBIA ルビア / DJ sarasa</h1>
			<CategoriesAndTags />
		</Layout>
	)
}

export default TagsPage
