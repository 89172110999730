import React from "react";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";
import { ScrollAndShow } from "../components/scroll-and-show";
import { useCategories } from "../hooks/useCategories";
import { useTags } from "../hooks/useTags";

export const CategoriesAndTags = () => {
	const [t, i18n] = useTranslation();

	const { categories } = useCategories();
	const { tags } = useTags();

	return (
		<div className="categories-and-tags">
			<ScrollAndShow>
				<div className="links-wrapper font-lato">
					<Link to="/stories">
						<div>STORIES</div>
					</Link>
					<Link to="/event_reports">
						<div>EVENT</div>
					</Link>
				</div>
			</ScrollAndShow>

			<ScrollAndShow>
				<div className="title font-lato">CATEGORIES</div>
				<div className="category-list">
					{categories.map((category, index) => (
						<Link to={`/categories/${category.title}`}>
							<div
								className={`${
									i18n.language === "en" && category.titleEn
										? "font-lato"
										: "font-yu"
								}`}
								key={index}
							>
								{(i18n.language === "en" && category.titleEn) || category.title}
							</div>
						</Link>
					))}
				</div>
			</ScrollAndShow>

			<ScrollAndShow>
				<div className="title font-lato">TAGS</div>
				<div className="tag-list">
					{tags.map((tag, index) => (
						<Link to={`/tags/${tag.title}`}>
							<div
								className={`${
									i18n.language === "en" && tag.titleEn
										? "font-lato"
										: "font-yu"
								}`}
								key={index}
							>
								#{(i18n.language === "en" && tag.titleEn) || tag.title}
							</div>
						</Link>
					))}
				</div>
			</ScrollAndShow>
		</div>
	);
};
