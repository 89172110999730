import { useState, useEffect } from 'react'
import { request } from '../lib/axisClient'
import { Category } from '../types/category'

export const useCategories = () => {
	const [categories, setCategories] = useState<Category[]>([])

	useEffect(() => {
		fetchCategories()
	}, [])

	const fetchCategories = async () => {
		try {
			const res = await request({
				url: '/categories',
				method: 'GET'
			})
			setCategories(res.contents)
		} catch (e) {
			console.log(e)
		}
	}

	return { categories }
}
